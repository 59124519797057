import { template as template_4f61d5b487e54f039cdb2b08feb77cbc } from "@ember/template-compiler";
const FKText = template_4f61d5b487e54f039cdb2b08feb77cbc(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
